import { Appearance } from "@frigade/react";

const COLOR_PRIMARY = "#0A88A4";
const COLOR_TEXT = "#3F525B";
const COLOR_HEADER = "#263137";

const APPEARANCE = {
  theme: {
    colorPrimary: COLOR_PRIMARY,
    colorSecondary: "#EEEEEE", // Used in the background of the checklist progress bars
    colorText: COLOR_TEXT,
    borderRadius: 6,
  },
  styleOverrides: {
    carouselTitle: {
      color: COLOR_HEADER,
    },
    carouselCardTitle: {
      color: COLOR_HEADER,
    },
    tooltipImageContainer: {
      minHeight: "auto",
      padding: "8px",
    },
    progressWrapper: "w-full justify-left xs:w-1/2",
    modalContainer: {
      height: "auto",
    },
    smallTitle: "text-lg font-bold leading-6 text-gray-900 mb-1",
    smallSubtitle: "text-sm text-gray-600",
    tooltipContentContainer: {
      paddingTop: "4px",
    },
  },
  tourHighlightContainer: {
    zIndex: 30,
  },
} as Appearance;

const FRIGADE_CONFIG = {
  COLOR_PRIMARY,
  FLOW_ID_CHECKLIST: "flow_KhjvWqxyUV9dungF",
  FLOW_ID_PROFILE_COMPLETION: "flow_rnVVfjX5rDzWd2cV",
  FLOW_ID_TOUR: "flow_VF5ivKfatSDktNTM",
  FLOW_ID_ONBOARDING: "flow_K4jPJtKA74ya9FXw",
  FLOW_ID_WELCOME_ANNOUNCEMENT: "flow_qWZQBEaJ",
  FLOW_ID_JOB_SEARCH_ANNOUNCEMENT: "flow_0NcMYsrn",
  FLOW_ID_POST_SUBSCRIBE_ANNOUNCEMENT: "flow_HwLjVERK",
  FLOW_ID_RESUME_BUILDER: "flow_o0pmThjfdKHYHc41",
  FLOW_ID_COVER_LETTER: "flow_3YPggDfmhn1xNcct",
  FLOW_ID_QUESTION_RESPONSE: "flow_3YPggDfmhn1xNcct",
  FLOW_ID_RESUME_REVIEW: "flow_PMupCzQ4",
  FLOW_ID_48_HOUR_DISCOUNT: "flow_qr5uNFqt",
  APPEARANCE,
  STEP_ID_COMPLETE_YOUR_PROFILE: "complete-your-profile",
  STEP_ID_VIEW_MATCHES: "view-matches",
  STEP_ID_VIEW_JOB_LIST: "view-job-list",
  STEP_ID_APPLY_FIRST_JOB: "apply-first-job",
  STEP_ID_INSTALL_EXTENSION: "install-extension",
  STEP_ID_UPDATE_JOB_APPLICATION_STATUS: "update-job-application-status",
  STEP_ID_ONBOARDING_ROLES: "onboarding-roles",
  STEP_ID_ONBOARDING_EDUCATION: "onboarding-education",
  STEP_ID_ONBOARDING_EXPERIENCE: "onboarding-experience",
  STEP_ID_ONBOARDING_WORKAUTH: "onboarding-workauth",
  STEP_ID_ONBOARDING_EEO: "onboarding-eeo",
  STEP_ID_ONBOARDING_SKILLS: "onboarding-skills",
  STEP_ID_ONBOARDING_PERSONAL: "onboarding-personal",
  STEP_ID_ONBOARDING_URLS: "onboarding-urls",
  STEP_ID_ONBOARDING_EXTENSION: "onboarding-extension",
};

export const ANNOUNCEMENT_CSS = {
  ".fr-close": {
    zIndex: 100,
  },
  ".fr-card": {
    borderRadius: "20px",
    justifyContent: "space-between",
    maxWidth: "456px",
    maxHeight: "593px",
    height: "100%",
    padding: "0px",
  },
  ".fr-dialog": {
    overflow: "hidden",
    borderRadius: "20px",
    outline: "none",

    "&:after": {
      content: '"<Frigade.Announcement />"',
    },
  },
  ".fr-announcement-step": {
    height: "100%",
    justifyContent: "space-between",
    padding: "40px",
  },
  ".fr-announcement-header": {
    order: 2,
  },
  ".fr-image": {
    order: 0,
    height: "100%",
    maxHeight: "284px",
    borderRadius: "8px 8px 0 0",
    aspectRatio: "1.9047619048",
    margin: "-40px -40px 0 -40px",
    width: "calc(100% + 80px)",
    maxWidth: "calc(100% + 80px)",
  },
  ".fr-title": {
    fontWeight: "bold",
    fontSize: "28px",
    color: "#263137",
    marginBottom: "16px",
    lineHeight: "normal",
  },
  ".fr-subtitle": {
    fontSize: "16px",
    color: "#263137",
    lineHeight: "normal",
  },
  ".fr-progress": {
    order: 1,
  },
  ".fr-announcement-footer": {
    order: 3,
  },
  ".fr-button-primary": {
    zIndex: 100,
    backgroundColor: "#12A1C0",
    margin: "auto",
    color: "white",
    borderRadius: "8px",
    borderColor: "white",
    padding: "12px 20px",
    flexGrow: 0,
    "-webkit-flex-grow": 0,
    "&:hover": {
      backgroundColor: "#0A88A4",
    },
  },
  ".fr-button-title": {
    fontSize: "16px",
    fontWeight: "500",
  },
  ".fr-button-secondary": {
    zIndex: 100,
    backgroundColor: "white",
    color: "#3F525B",
    fontSize: "16px",
    borderRadius: "8px",
    borderColor: "#e5e7eb",
    "&:hover": {
      color: "#263137",
      backgroundColor: "white",
      borderColor: "#263137",
    },
  },
  ".fr-progress-dot": {
    fill: "#ECEFF5",
  },
  ".fr-progress-dot-selected": {
    fill: "#3F525B",
  },
};

export default FRIGADE_CONFIG;
