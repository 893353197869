/* eslint-disable camelcase */
import * as Sentry from "@sentry/nextjs";

import {
  Application,
  CopilotCoverLetter,
  CopilotQuestionResponse,
  CustomResume,
  CustomResumeCreate,
  FetchResponse,
  GenerateAllRequest,
  GenerateBulletRequest,
  GenerateSummaryRequest,
  ProjectRequest,
  RecommendationFeedback,
  ResumeParse,
  TrackerStatusResponse,
} from "interfaces/API";
import { CustomApp, SaveApp } from "modules/applications/CustomApp/interfaces";
import { stripNullCharacters } from "utils/helpers/helpers";
import { sendSabreMessage } from "utils/helpers/sabre";
import { mockedMatches } from "utils/testData";

import { fetchWrapper } from "./fetch";

export const getCandidateData = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/",
    auth: true,
  });

export const getLimitedCandidateData = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/candidate",
    auth: true,
  });

export const updateCandidateData = async (candidate_data: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: "/v2/candidate/me/",
    body: JSON.stringify(candidate_data),
    auth: true,
  });

export const getCandidatePreferences = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/preferences",
    auth: true,
  });

export const updateCandidatePreferences = async (preferences: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: "/v2/candidate/me/preferences",
    body: JSON.stringify(preferences),
    auth: true,
  });

export const uploadFile = async (file: File, endpoint: string): Promise<FetchResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchWrapper({
    method: "POST",
    url: endpoint,
    body: formData,
    auth: true,
    noHeader: true, // we want browser to handle the headers/boundary info automatically
  });
};

export const getProfileImage = (firstname: string, lastname: string): string =>
  `https://ui-avatars.com/api/?background=3ec0de&size=128&bold=true&color=fff&name=${firstname}+${lastname}`;

// Experience
export const getExperiences = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/experience/",
    auth: true,
  });

export const updateExperience = async (experience: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/experience/${experience.id}`,
    body: JSON.stringify(experience),
    auth: true,
  });

export const createExperience = async (experience: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/experience/",
    body: JSON.stringify(experience),
    auth: true,
  });

export const deleteExperience = async (experience_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/experience/${experience_id}`,
    auth: true,
  });

// Education
export const getEducations = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/education/",
    auth: true,
  });

export const updateEducation = async (education: {
  education_id: string;
  major_id: string;
  degree: number;
  gpa: number | null;
  id: string;
  start_month: number | null;
  start_year: number | null;
  grad_month: number | null;
  grad_year: number | null;
  education_custom_name: string | null;
  major_custom_name: string | null;
}): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/education/${education.id}`,
    body: JSON.stringify(education),
    auth: true,
  });

export const createEducation = async (education: {
  education_id: string;
  major_id: string;
  degree: number;
  gpa: number | null;
  start_month: number | null;
  start_year: number | null;
  grad_month: number | null;
  grad_year: number | null;
  education_custom_name: string | null;
  major_custom_name: string | null;
}): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/education/",
    body: JSON.stringify(education),
    auth: true,
  });

export const deleteEducation = async (education_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/education/${education_id}`,
    auth: true,
  });

// Industry
export const getIndustries = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/industry/",
    auth: true,
  });

export const createIndustry = async (industry: { industry_id: string }[]): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/industry/",
    body: JSON.stringify(industry),
    auth: true,
  });

export const deleteIndustry = async (industry_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/industry/${industry_id}`,
    auth: true,
  });

export const deleteIndustries = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/industry/`,
    auth: true,
  });

// Function
export const getFunctions = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/function/",
    auth: true,
  });

export const createFunction = async (functions: { function_id: string }[]): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/function/",
    body: JSON.stringify(functions),
    auth: true,
  });

export const deleteFunction = async (function_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/function/${function_id}`,
    auth: true,
  });

export const deleteFunctions = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/function/`,
    auth: true,
  });

// Region
export const getRegions = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/region/",
    auth: true,
  });

export const createRegion = async (region: { region_id: string }[]): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/region/",
    body: JSON.stringify(region),
    auth: true,
  });

export const deleteRegion = async (region_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/region/${region_id}`,
    auth: true,
  });

export const deleteRegions = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/region/`,
    auth: true,
  });

// Language
export const getLanguages = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/language/",
    auth: true,
  });

export const createLanguage = async (
  language: {
    language_id: string;
  }[]
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/language/",
    body: JSON.stringify(language),
    auth: true,
  });

export const deleteLanguages = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/language/`,
    auth: true,
  });

export const deleteLanguage = async (language_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/language/${language_id}`,
    auth: true,
  });

// Skill
export const getSkills = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/skill/",
    auth: true,
  });

export const updateSkill = async (
  skill: {
    preferred: boolean;
  },
  id: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/skill/${id}`,
    body: JSON.stringify(skill),
    auth: true,
  });

export const createSkill = async (
  skill: {
    skill_id: string;
    preferred: boolean;
    length: 1 | 2 | 3 | 4;
  }[]
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/skill/",
    body: JSON.stringify(skill),
    auth: true,
  });

export const deleteSkill = async (skill_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/skill/${skill_id}`,
    auth: true,
  });

export const deleteSkills = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/skill/`,
    auth: true,
  });

// Applications
export const getApplications = async (
  page: number,
  size: number,
  value = "",
  type: number | null = null,
  status: number | null = null,
  active: boolean | null = null,
  dateFrom: Date | null = null,
  dateTo: Date | null = null,
  native: boolean | null = null
): Promise<FetchResponse> => {
  let params = "";
  if (type !== null) params += `&job_type=${type}`;
  if (status !== null) params += `&status=${status}`;
  if (dateFrom !== null)
    params += `&date_applied_after=${dateFrom.toISOString().substring(0, 10)}T00:00:00.0000&date_status_after=${dateFrom.toISOString().substring(0, 10)}T00:00:00.0000`;
  if (dateTo !== null) {
    params += `&date_applied_before=${dateTo.toISOString().substring(0, 10)}T23:59:59.9999&date_status_before=${dateTo.toISOString().substring(0, 10)}T23:59:59.9999`;
  }
  if (active !== null) {
    params += `${active ? "&archived=false" : "&archived=true"}`;
  }
  if (native !== null) {
    params += `${native ? "&native=true" : "&native=false"}`;
  }

  return fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/tracker/?page=${page}&size=${size}&value=${value}${params}`,
    auth: true,
  });
};

export const getApplicationById = async (
  postingId: string,
  archived?: boolean
): Promise<FetchResponse<TrackerStatusResponse>> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/tracker/job-posting/${postingId}${archived != null ? `?archived=${archived}` : ""}`,
    auth: true,
  });

export const getTracker = async (id: string): Promise<FetchResponse<Application>> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/tracker/${id}/detail`,
    auth: true,
  });

export const getApplicationByURL = async ({
  page,
  size,
  url,
  tracked_obj,
  value,
}: {
  page: number;
  size: number;
  url?: string;
  tracked_obj?: string;
  value?: string;
}): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/tracker/job-posting/url?page=${page}&size=${size}${url ? `&url=${url}` : ""}${tracked_obj ? `&tracked_obj=${tracked_obj}` : ""}${value ? `&value=${value}` : ""}`,
    auth: true,
  });

export const createApplicationFromID = async (id: string, resume_id?: string | null): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/tracker/dunder/applied",
    body: JSON.stringify({ job_posting_id: id, resume_id: resume_id || null }),
    auth: true,
  });

export const createApplicationFromIDWithQuestions = async (formData: Record<string, unknown>): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/application/native",
    body: JSON.stringify(formData),
    auth: true,
  });

export const createCustomApplication = async (customApplication: CustomApp | SaveApp): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/tracker/dunder/save",
    body: JSON.stringify(customApplication),
    auth: true,
  });

export const updateApplication = async (application: Partial<Application>, id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/tracker/${id}`,
    body: JSON.stringify(application),
    auth: true,
  });

export const archiveAllCandidateApplications = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/tracker/archive-all",
    auth: true,
  });

export const deleteApplication = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/tracker/${id}`,
    auth: true,
  });

export const getTrackerCSV = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/tracker/export/csv`,
    auth: true,
    blob: true,
  });

// export const getApplicationChart = async (): Promise<FetchResponse> =>
//   fetchWrapper({
//     method: "GET",
//     url: `/v2/candidate/me/application/chart`,
//     auth: true,
//     blob: true,
//   });

// JobList
export const followJobList = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/following/job-list/${id}`,
    auth: true,
  });

export const unfollowJobList = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/following/job-list/${id}`,
    auth: true,
  });

export const getFollowedJobLists = async (page: number, size: number, value = ""): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/following/job-list?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

// Saving jobs
export const addToShortlist = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/tracker/dunder/save",
    body: JSON.stringify({ job_posting_id: id }),
    auth: true,
  });

export const removeFromShortlist = async (job_posting_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/tracker/job-posting/${job_posting_id}`,
    auth: true,
  });

// Recommendation
export const getRecommendations = async (refresh: boolean, matchGroup: number | null = null): Promise<FetchResponse> =>
  process.env.NEXT_PUBLIC_NODE_ENV !== "production"
    ? mockedMatches
    : fetchWrapper({
        method: "GET",
        url: `/v2/candidate/me/recommendation/?refresh=${refresh}${matchGroup ? `&match_group=${matchGroup}` : ""}`,
        auth: true,
      });

export const getMatchGroups = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/recommendation/match-groups`,
    auth: true,
  });

export const getWeeklyGoal = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/weekly-application-goal",
    auth: true,
  });

export const updateWeeklyGoal = async (weekly_goal: number): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: "/v2/candidate/me/weekly-application-goal",
    body: JSON.stringify({ weekly_goal }),
    auth: true,
  });

export const getDaysStreak = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/recommendation/streaks`,
    auth: true,
  });

export const getRecentlyViewed = async (
  page: number,
  size: number,
  value: string,
  active?: boolean
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/recommendation/viewed?page=${page}&size=${size}&value=${value}${
      active == null ? "" : `&active=${active}`
    }`,
    auth: true,
  });

export const addRecommendationReaction = async (
  id: string,
  reaction: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  matchGroup: number | null = null
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/recommendation/",
    body: JSON.stringify({ job_posting_id: id, type: reaction, source: 2, match_group: matchGroup }),
    auth: true,
  });

export const deleteRecommendationReaction = async (jobId: string, reactionType: number): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/recommendation/${jobId}/${reactionType}`,
    auth: true,
  });

export const getRecommendationFeedback = async (): Promise<FetchResponse<RecommendationFeedback>> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/recommendation/feedback",
    auth: true,
  });

// Custom Resume
export const uploadAndParseResume = async (file: File): Promise<FetchResponse<ResumeParse>> => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/resume/resume-parse",
    body: formData,
    auth: true,
    noHeader: true, // we want browser to handle the headers/boundary info automatically
  });
};

export const uploadCandidateResume = async (file: File, name: string | null = null): Promise<FetchResponse> => {
  const formData = new FormData();
  if (name) formData.append("name", name);
  formData.append("file", file);

  return fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/resume/upload",
    body: formData,
    auth: true,
    noHeader: true, // we want browser to handle the headers/boundary info automatically
  });
};

export const uploadBuiltResume = async (resumeData: CustomResumeCreate, file: File): Promise<FetchResponse> => {
  const formData = new FormData();
  formData.append("resume_data", JSON.stringify(stripNullCharacters(resumeData)));
  formData.append("file", file);

  return fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/resume/builder/",
    body: formData,
    auth: true,
    noHeader: true, // we want browser to handle the headers/boundary info automatically
  });
};

export const getCustomResume = async (id: string): Promise<FetchResponse<CustomResume>> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/resume/${id}`,
    auth: true,
  });

export const getCandidateResumes = async (
  page: number,
  size: number,
  value = "",
  tracked_obj = ""
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/resume/?query=${value}${tracked_obj ? `&tracked_obj=${tracked_obj}` : ""}`,
    auth: true,
  });

export const getCandidateResumesPaginate = async (
  page: number,
  size: number,
  value = "",
  tracked_obj: string | null = null,
  generated: boolean | null = null,
  defaultFirst: boolean = true
): Promise<FetchResponse> => {
  let params = "";
  if (tracked_obj !== null) params += `&tracked_obj=${tracked_obj}`;
  if (generated != null) params += `&generated=${generated}`;
  params += `&first_page_load_default_resume=${defaultFirst}`;

  return fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/resume/paginate?page=${page}&size=${size}&value=${value}${params}`,
    auth: true,
  });
};

export const getGeneratedResumes = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/resume/?generated=true`,
    auth: true,
  });

export const updateCandidateResume = async (
  id: string,
  resumeData: Partial<CustomResumeCreate>,
  file: File
): Promise<FetchResponse> => {
  const formData = new FormData();
  formData.append("resume_data", JSON.stringify(stripNullCharacters(resumeData)));
  formData.append("file", file);

  return fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/resume/${id}`,
    body: formData,
    auth: true,
    noHeader: true, // we want browser to handle the headers/boundary info automatically
  });
};

export const updateResumeName = async (id: string, name: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/resume/name/${id}`,
    body: JSON.stringify({ name }),
    auth: true,
  });

export const deleteCandidateResume = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/resume/${id}`,
    auth: true,
  });

export const setDefaultResume = async (id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/resume/default/${id}`,
    auth: true,
  });

export function sendJobReaction(
  id: string,
  reaction: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  matchGroup?: number | null
): void {
  addRecommendationReaction(id, reaction, matchGroup).then((res) => {
    if (!res || !res.success)
      if (!res?.error?.detail?.includes("Not authenticated"))
        Sentry.captureException(new Error(res?.error?.extra || res?.error?.detail || `Error adding reaction to ${id}`));
  });
}

// Simplify Plus
export const getSubscriptionStatus = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/subscription-status`,
    auth: true,
  });

export const getSubscriptionDetails = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/candidate/me/subscription`,
    auth: true,
  });

export const generateResumeBullets = async (requestBody: GenerateBulletRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/resume/bullets",
    body: JSON.stringify(requestBody),
    auth: true,
  });

export const generateResumeBulletsWithJob = async (requestBody: GenerateBulletRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/resume/bullets/job",
    body: JSON.stringify(requestBody),
    auth: true,
  });

export const generateResumeAllWithJob = async (requestBody: GenerateAllRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/resume/bullets/job/all",
    body: JSON.stringify(requestBody),
    auth: true,
  });

export const generateResumeSummary = async (requestBody: GenerateSummaryRequest): Promise<FetchResponse> => {
  const baseURL = "/v2/candidate/me/copilot/resume/summary";

  return fetchWrapper({
    method: "POST",
    url: `${baseURL}${requestBody?.job_title || requestBody?.tracked_obj ? "/job" : ""}`,
    body: JSON.stringify(requestBody),
    auth: true,
  });
};

export const generateResumeSummaryWithJob = async (requestBody: GenerateSummaryRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/resume/summary/job",
    body: JSON.stringify(requestBody),
    auth: true,
  });

export const getSabreRemoteConfig = async (): Promise<Response> => fetch("https://sabre.simplify.jobs");

export const importProfile = async (
  boardKey: string,
  setImporting: (arg0: boolean) => void,
  reloadData: (retries: number) => void,
  setErrorMessage: (arg0: string) => void,
  next: () => void
): Promise<boolean> => {
  try {
    const response = await sendSabreMessage({ method: "importProfile", boardKey }, 10000, 5);

    if (response === true) {
      setTimeout(() => {
        setImporting(false);
        setErrorMessage("");
        reloadData(0);
        next();
      }, 1000);
      return true;
    }

    setTimeout(() => {
      setImporting(false);
      reloadData(0);
      setErrorMessage(`Issue importing ${boardKey} profile. Some information may be missing.`);
    }, 1000);

    return false;
  } catch (error) {
    setImporting(false);
    setErrorMessage(`Issue importing ${boardKey} profile. Please make sure you installed the extension and try again.`);
    return false;
  }
};

export const sendCustomResume = async (
  resumeId: string,
  tabId: string,
  setImporting: (arg0: boolean) => void,
  setErrorMessage: (arg0: string) => void,
  setShowErrorModal: (arg0: boolean) => void
): Promise<boolean> => {
  try {
    const response = await sendSabreMessage({ method: "setResumeId", resumeId, tabId });

    if (response === true) {
      setTimeout(() => {
        setImporting(false);
        setErrorMessage("");
      }, 1000);
      return true;
    }

    setTimeout(() => {
      setImporting(false);
      setErrorMessage("Issue sending custom resume.");
      setShowErrorModal(true);
    }, 1000);

    return false;
  } catch (error) {
    setImporting(false);
    setErrorMessage("Issue sending custom resume. Please make sure you installed the extension and try again.");
    setShowErrorModal(true);
    return false;
  }
};

export const copilotCoverLetter = async (requestBody: CopilotCoverLetter): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/cover-letter",
    body: JSON.stringify(requestBody),
    auth: true,
  });

export const copilotQuestionResponse = async (requestBody: CopilotQuestionResponse): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/copilot/question",
    body: JSON.stringify(requestBody),
    auth: true,
  });

// Project
export const getProjects = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/project/",
    auth: true,
  });

export const updateProject = async (project: ProjectRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/candidate/me/project/${project.id}`,
    body: JSON.stringify(project),
    auth: true,
  });

export const createProject = async (project: ProjectRequest): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/candidate/me/project/",
    body: JSON.stringify(project),
    auth: true,
  });

export const deleteProject = async (project_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/candidate/me/project/${project_id}`,
    auth: true,
  });

export const heartbreak = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/heartbeat/break",
    auth: true,
  });

export const getAppliedJobs = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: "/v2/candidate/me/tracker/job-posting/applied",
    auth: true,
  });
